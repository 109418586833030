var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
export default Vue.extend({
    apollo: {
        pendingBankTransferPurchase: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query BankTransferPurchaseQuery($id: ID!) {\n          pendingBankTransferPurchase(id: $id) {\n            __typename\n            id\n            proFormaInvoiceFileUrl\n          }\n        }\n      "], ["\n        query BankTransferPurchaseQuery($id: ID!) {\n          pendingBankTransferPurchase(id: $id) {\n            __typename\n            id\n            proFormaInvoiceFileUrl\n          }\n        }\n      "]))),
            variables: function () {
                return {
                    id: this.$route.params.pendingPurchaseId,
                };
            },
        },
    },
});
var templateObject_1;
